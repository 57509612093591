<template>
    <div class="alert d-flex justify-content-between align-items-center" :class="alertStyle">
        <span>{{ message }}</span>
        <a href="#" class="alert-close" @click="$emit('close')">X</a>
    </div>
</template>

<script>
export default {
    name: 'alert',

    props: {
        message: {
            type: String,
            required: true,
        },

        alertStyle: {
            type: String,
            default: 'alert-secondary'
        }
    },
}
</script>

<style scoped>
.alert {
    padding: 0.5rem 1rem;
}

.alert-close {
    color: #444;
    font-size: 16px;
    font-weight: 600;;
}
</style>