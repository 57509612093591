<template>
    <div v-if="product">
        <div class="content">
            <div class="container">
                <router-link to="/" class="btn btn-secondary continue-shopping">Continue Shopping</router-link>

                <div class="row">
                    <div class="col-md-4" v-if="product.image">
                        <img :src="currentImage" :alt="product.title" class="image-large" />

                        <div class="gallery flex flex-wrap my-3" v-if="product.images.length">
                            <a href="#" @click.prevent="handleSelectImage(product.image)">
                                <img :src="product.image" class="image-thumbnail mr-1" />
                            </a>

                            <a v-for="image in product.images" :key="image.id" href="#" @click.prevent="handleSelectImage(image.filename)">
                                <img :src="image.filename" class="image-thumbnail mr-1" />
                            </a>
                        </div>
                    </div>

                    <div class="col-md-8">
                        <h1 class="product-title mt-1">{{ product.title }}</h1>

                        <p class="description" v-html="product.description"></p>

                        <div v-if="product.price_2plus" class="price2-grn">Buy 2 for {{ price2plus | toCurrency }}</div>

                        <div class="price mb-3">
                            <div v-if="product.price_2plus && product.quantity >= 2">
                                <span class="text-muted strikethrough">${{ product.price }}</span>
                                <span class="ml-2 price2-red">${{ product.price_2plus }}/{{ product.size }}</span>
                            </div>
                            <div v-else>${{ product.price }}/{{ product.size }}</div>
                        </div>

                        <product-add-to-cart
                            :product="product"
                            :show-add-to-cart-button="true"
                        ></product-add-to-cart>


                        <div class="mt-5">
                            <div v-if="shown_subitems.length">
                                <div v-for="subitem in shown_subitems" :key="subitem.id">
                                    {{ subitem.qty * 1 }} {{ subitem.name }} {{ subitem.unit }}
                                </div>
                            </div>
                            <div v-else-if="product.contents">
                                <p v-html="nl2br(product.contents)" class="mb-3"></p>
                            </div>

                            <div class="ql-editor" v-html="product.long_description"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <order-summary></order-summary>
    </div>
</template>

<script>
    import OrderSummary from '@/components/OrderSummary'
    import ProductAddToCart from '@/components/ProductAddToCart.vue'
    import { trackViewItem } from '../dataLayer.js';

    export default {
        name: 'Product',

        components: {
            OrderSummary,
            ProductAddToCart,
        },

        metaInfo () {
            let description = '';

            if (this.product?.long_description) {
                // stripping html tags, ideally this should be done on server
                description = this.product.long_description.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 150);
            }

            return {
                title: this.product ? this.product.title : '',

                meta: [
                    { charset: 'utf-8' },
                    {
                        name: 'description',
                        content: description
                    },
                ]
            }
        },

        data () {
            return {
                dayOfWeek: new Date().getDay(),
                showAvailableQty: false,
                currentImage: null,
                hard_max: 999,
            }
        },

        computed: {
            product () {
                return this.$store.getters['product'](this.$route.params.slug);
            },
            price2plus () {
                if (this.product.price_2plus === null) return false;

                return Math.round(this.product.price_2plus * 2 * 100) / 100;
            },
            shown_subitems () {
                return this.product.subitems.filter(product => !product.hidden_from_customer);
            },
        },

        methods: {
            nl2br (str, is_xhtml) {
                if (typeof str === 'undefined' || str === null) {
                    return '';
                }
                var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
                return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
            },

            handleSelectImage (image) {
                this.currentImage = image;
            }
        },

        watch: {
            product (newValue) {
                this.currentImage = newValue ? newValue.image : null;
            },
        },

        mounted () {
            if (this.product) {
                this.currentImage = this.product.image;
            } else {
                this.$router.push('/404');
            }
        },

        activated () {
            window.scroll(0, 0);
        },

        beforeRouteEnter (to, from, next) {
            next(vm => {
                trackViewItem(vm.product);
            });
        }
    }
</script>

<style scoped>
    .content {
        padding-bottom: 10rem;
    }

    .price {
        font-size: 1.375rem;
    }

    .input-group {
        max-width: 180px;
        margin-top: 0.5rem;
    }

    .input-group .qty {
        border-radius: 0 !important;
        font-size: 19px;
        height: 40px;
        padding: 0 6px;
        text-align: center;
    }

    .image-large {
        max-width: 100%;
        max-height: 300px;
        display: block;
        margin: 0 auto;
    }

    .image-thumbnail {
        width: 36px;
        height: 36px;
        object-fit: contain;
    }

    .gallery {
        text-align: center;
    }

    .continue-shopping {
        padding: 4px 8px;
        height: calc(1.5em + .5rem + 2px);
        margin-bottom: 1rem;
    }

    @media (min-width: 768px) {
        .gallery {
            text-align: left;
        }

        .image-large {
            display: inline;
            max-height: 500px;
        }

        .ql-editor {
            white-space: pre;
        }

        .continue-shopping {
            padding: 6px 12px;
            height: calc(1.5em + .75rem + 2px);
            margin-bottom: 2rem;
        }
    }

</style>
