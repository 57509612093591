<template>
    <my-account-layout>
        <loading v-if="loading" height="25vh" />

        <div v-else>
            <div class="d-flex align-items-center mb-2">
                <router-link class="btn btn-outline-secondary mr-3" :to="{ name: 'orderHistory' }">Back to Order History</router-link>

                <button type="button" class="btn btn-primary" @click="handleReorder">
                    Re-order
                    <img v-if="reorderLoading" src="/images/icons/loading-1s-200px.svg" width="18px" height="18px" />
                    <img v-if="reorderComplete" src="/images/icons/check-white.svg" width="18px" height="18px" />
                </button>
            </div>

            <h1 class="mt-3 mb-2">Order Summary</h1>

            <div class="row order-header">
                <div class="col-sm-6 col-lg-3">
                    <span class="font-weight-bold">Order #:</span>
                    <p class="mb-1">{{ order.order_number }}</p>

                    <span class="font-weight-bold">Order Date:</span>
                    <p>{{ formatOrderedDate(order.created_at) }}</p>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <span class="font-weight-bold">Shipping Method:</span>
                    <p class="mb-1 text-capitalize">{{ order.shipping_method }}</p>

                    <span class="font-weight-bold">Shipping Date:</span>
                    <p >{{ formatShippingDate(order.ship_date) }}</p>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <span class="font-weight-bold">Shipping Address:</span>

                    <p>
                        {{ order.shipping_first_name }} {{ order.shipping_last_name }} <br>
                        {{ order.shipping_address_1 }} <br>
                        <span v-if="order.shipping_address_2">{{ order.shipping_address_2 }} <br></span>
                        {{ order.shipping_city }} {{ order.shipping_province}}, {{ order.shipping_postal }}
                    </p>
                </div>

                <div class="col-sm-6 col-lg-3" v-if="order.billing_address_1">
                    <span class="font-weight-bold">Billing Address:</span>

                    <p>
                        {{ order.billing_first_name }} {{ order.billing_last_name }} <br>
                        {{ order.billing_address_1 }} <br>
                        <span v-if="order.billing_address_2">{{ order.billing_address_2 }} <br></span>
                        {{ order.billing_city }} {{ order.billing_province}}, {{ order.billing_postal }}
                    </p>
                </div>
            </div>


            <table class="table table-sm my-2">
                <thead>
                    <tr>
                        <th class="d-none d-md-table-cell"></th>
                        <th class="text-left">Product</th>
                        <th>Tax</th>
                        <th class="text-center">Qty</th>
                        <th class="text-right">Price</th>
                        <th class="text-right"><span class="d-none d-lg-inline">Line</span> Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in order.order_items" :key="item.id">
                        <td class="d-none d-md-table-cell text-center">
                            <img :src="imageUrl(item)" width="50" height="50" class="product-image" />
                        </td>
                        <td>
                            {{ item.title }} - {{ item.description }}
                        </td>
                        <td>
                            {{ item.tax ? 'Yes' : 'No' }}
                        </td>
                        <td class="text-center">
                            {{ item.quantity * 1 }}
                        </td>
                        <td class="text-right">
                            {{ parseFloat(item.price) | toCurrency }}
                        </td>
                        <td class="text-right">
                            {{ total(item) | toCurrency }}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr v-if="order.subtotal !== order.total">
                        <td class="d-none d-md-table-cell"></td>
                        <th class="text-right pt-4" colspan="4">Subtotal:</th>
                        <td class="text-right pt-4">{{ order.subtotal | toCurrency }}</td>
                    </tr>
                    <tr v-if="order.fee_for_delivery">
                        <td class="d-none d-md-table-cell"></td>
                        <th class="text-right" colspan="4">Delivery:</th>
                        <td class="text-right">{{ order.fee_for_delivery | toCurrency }}</td>
                    </tr>
                    <tr v-if="order.tax">
                        <td class="d-none d-md-table-cell"></td>
                        <th class="text-right" colspan="4">Tax:</th>
                        <td class="text-right">{{ order.tax | toCurrency }}</td>
                    </tr>
                    <tr>
                        <td class="d-none d-md-table-cell"></td>
                        <th class="text-right" :class="{ 'pt-4' : order.subtotal === order.total }" colspan="4">Total:</th>
                        <td class="text-right" :class="{ 'pt-4' : order.subtotal === order.total }">{{ order.total | toCurrency }}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </my-account-layout>
</template>

<script>
import { mapState } from 'vuex';
import { DateTime } from "luxon";
import MyAccountLayout from '@/views/Layout/MyAccount';
import Loading from '@/components/Loading';

export default {
    components: {
        MyAccountLayout,
        Loading,
    },

    data() {
        return {
            order: null,
            loading: false,
            reorderLoading: false,
            reorderComplete: false,
        }
    },

    computed: {
        ...mapState({
            currentUser: state => state.user.currentUser,
        }),
    },

    methods: {
        fetchOrder () {
            const orderId = this.$route.params.id;
            const route = process.env.VUE_APP_API_WHOLESALE + '/api/user/orders/' + orderId;

            this.loading = true;

            this.axios.get(route)
                .then(response => {
                    this.order = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        total (item) {
            const total = item.quantity * item.price;

            return Math.round(total * 100) / 100;
        },

        imageUrl (item) {
            return process.env.VUE_APP_API_WHOLESALE + '/storage/' + item.product.image;
        },

        formatOrderedDate(date) {
            const newDate = DateTime.fromISO(date);

            return newDate.toLocaleString(DateTime.DATE_MED);
        },

        formatShippingDate(date) {
            const newDate = DateTime.fromSQL(date);

            return newDate.toLocaleString(DateTime.DATE_MED);
        },

        handleReorder () {
            const orderId = this.$route.params.id;
            const route = process.env.VUE_APP_API_WHOLESALE + '/api/user/orders/' + orderId + '/reorder';

            this.reorderLoading = true;
            this.reorderComplete = false;

            this.axios.post(route)
                .then(response => {
                    this.$store.dispatch('fetchCart');

                    if (response.data === 'success') {
                        this.reorderComplete = true;
                    }
                })
                .finally(() => {
                    this.reorderLoading = false;
                });
        },
    },

    created () {
        this.fetchOrder();
    }
}
</script>

<style scoped>
.order-header span,
.order-header p {
    font-size: 1rem;
}

.table tfoot th,
.table tfoot td {
    border-top: none;
    padding-top: 0;
    padding-bottom: 0;
}

.product-image {
    max-height: 40px;
    max-width: 50px;
    width: auto;
}
</style>
