<template>
    <modal v-show="show" @close="handleClose" size="larger" :closeable="true" class="outlined">
        <template #header>
            <a href="#" class="close-modal" @click.prevent="handleClose">X</a>
        </template>

        <template #body>
            <div style="padding-left: 15px; padding-right: 15px; overflow-x: auto;" v-if="product">
                <div class="row">
                    <div class="col-md-6">
                        <div class="featured text-center">
                            <img :src="currentImage" class="image-large" />
                        </div>

                        <div class="gallery flex flex-wrap my-3" v-if="product.images.length">
                            <a href="#" @click.stop.prevent="handleSelectImage(product.image)">
                                <img :src="product.image" class="image-thumbnail mr-1" />
                            </a>

                            <a v-for="image in product.images" :key="image.id" href="#" @click.stop.prevent="handleSelectImage(image.filename)">
                                <img :src="image.filename" class="image-thumbnail mr-1" />
                            </a>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <h3 v-text="product.title" class="mt-0 mb-2"></h3>

                        <p class="description mb-3" v-text="product.description"></p>

                        <div v-if="product.price_2plus" class="price2-grn">Buy 2 for {{ price2plus | toCurrency }}</div>

                        <div class="price mb-3">
                            <div v-if="product.price_2plus && product.quantity >= 2">
                                <span class="text-muted strikethrough">${{ product.price }}</span>
                                <span class="ml-2 price2-red">${{ product.price_2plus }}/{{ product.size }}</span>
                            </div>
                            <div v-else>${{ product.price }}/{{ product.size }}</div>
                        </div>

                        <product-add-to-cart
                            :product="product"
                            :show-add-to-cart-button="true"
                        ></product-add-to-cart>

                        <div>
                            <div v-if="shown_subitems.length" class="my-3">
                                <div v-for="subitem in shown_subitems" :key="subitem.id">
                                    {{ subitem.qty * 1 }} {{ subitem.name }} {{ subitem.unit }}
                                </div>
                            </div>
                            <div v-else-if="product.contents">
                                <p v-html="nl2br(product.contents)" class="mb-3"></p>
                            </div>
                        </div>

                        <div style="white-space: pre;" class="ql-editor" v-html="product.long_description"></div>
                    </div>
                </div>
            </div>
        </template>

        <template #footer></template>
    </modal>
</template>

<script>
    import Modal from './Modal'
    import ProductAddToCart from '@/components/ProductAddToCart.vue'

    export default {
        components: {
            Modal,
            ProductAddToCart,
        },

        props: ['product', 'show'],

        data () {
            return {
                currentImage: null,
            }
        },

        methods: {
            nl2br (str, is_xhtml) {
                if (typeof str === 'undefined' || str === null) {
                    return '';
                }
                var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
                return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
            },

            handleSelectImage (image) {
                this.currentImage = image;
            },

            handleClose () {
                this.$emit('close');
                this.$router.push('/');
            }
        },

        computed: {
            price2plus () {
                if (this.product.price_2plus === null) return false;

                return Math.round(this.product.price_2plus * 2 * 100) / 100;
            },

            shown_subitems () {
                return this.product.subitems.filter(product => !product.hidden_from_customer);
            },
        },

        watch: {
            product () {
                this.currentImage = this.product.image;
            }
        },
    }
</script>

<style scoped>
    h3 {
        color: #000;
        font-size: 1.5rem;
        line-height: 1.2;
    }

    .unit {
        line-height: 1.2;
        color: #85C71E;
        font-weight: bold;
        font-size: 0.9rem;
    }

    .price {
        font-size: 1.375rem;
        color: #000;
    }

    @media (min-width: 1024px) {
        h3 {
            font-size: 2.125rem;
        }

        .price {
            font-size: 1.5rem;
        }
    }

    p {
        font-size: 16px;
    }

    .modal-container {
        padding: 30px;
    }

    .image-large {
        height: 180px;
        object-fit: cover;
        max-width: 100%;
    }

    @media (min-width: 1024px) {
        .image-large {
            height: auto;
            max-height: 360px;
        }
    }

    .image-thumbnail {
        width: 36px;
        height: 36px;
        object-fit: contain;
    }

    .gallery {
        text-align: center;
    }

    @media (min-width: 768px) {
        .gallery {
            text-align: left;
        }
    }
</style>