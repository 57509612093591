<template>
    <modal v-show="show" class="outlined" :closeable="false">
        <template #header></template>

        <template #body>
            <div class="text-center">
                <p>We are closed Sunday so our staff can spend time with their families. Our website will be open again Monday.</p>
                <p>We appreciate your support and look forward to serving you soon!</p>
            </div>
        </template>

        <template #footer></template>
    </modal>
</template>

<script>
    import Modal from '@/components/Modal'

    export default {
        components: {
            Modal,
        },

        props: ['show'],
    }
</script>

<style scoped>

</style>