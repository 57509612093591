import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import { Integrations } from "@sentry/tracing"
import * as Sentry from "@sentry/vue"
import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'
import TextareaAutosize from 'vue-textarea-autosize'
import App from './App.vue'
import router from './router'
import store from './store'

axios.defaults.withCredentials = true;

// * Fetch a CSRF token before each post request. See https://laravel.com/docs/8.x/sanctum#spa-authenticating
axios.interceptors.request.use(async config => {
    if (config.method === 'post') {
        await axios.get(process.env.VUE_APP_API_WHOLESALE + '/sanctum/csrf-cookie');
        return config;
    }

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// * Redirecting user to login page if their session is invalid
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error?.response?.status === 503) {
        // ! Catching after replace to prevent duplicateNavigation errors.
        router.replace({ name: 'maintenance' }).catch(() => {});
    } else if(error?.response?.status === 401) {
        store.commit('setCurrentUser', null);

        router.replace({
            name: 'home',
            query: {
                login: '1',
            }
        });
    } else if(error?.response?.status === 403) {
        store.commit('setCurrentUser', null);
        window.location = '/';
    }

    return Promise.reject(error);
});

window.$ = window.jQuery = require('jquery');

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueMeta)
Vue.use(TextareaAutosize)
Vue.use(VueCookies)

Vue.directive('roundUp', {
    update: function (el) {
        if (el.value !== '') {
            var event = new Event('input', { bubbles: true });
            el.value = Math.ceil(el.value);
            el.dispatchEvent(event);
        }
    }
})

Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    });
    return formatter.format(value);
});

if (process.env.VUE_APP_SENTRY_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: "https://490b420b3a80480288b31e1723c06a03@o1112662.ingest.sentry.io/6142577",
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", "fresherproduce.me:8080", 'fresherproduce.innovel.app', "fresherproduce.ca", /^\//],
            }),
        ],
        tracesSampleRate: 0.05, // Capturing 5% of traffic for performance analysis
        environment: 'production',
        logErrors: true,
    });
}

new Vue({
    router,
    store,

    metaInfo: {
        title: 'Home',
        titleTemplate: 'Produce Express | %s',

        meta: [
            { vmid: 'robots', name: 'robots', content: 'Index, Follow' }
        ],
    },

    render: h => h(App),
}).$mount('#app')

