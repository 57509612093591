<template>
    <form @submit.prevent="handleSubmit">
        <div class="mb-3">
            <h2 class="h4">Account Information</h2>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>First Name</label>
                        <input type="text" class="form-control" v-model="form.first_name" />
                        <span v-if="errors.first_name" class="text-danger">{{ errors.first_name[0] }}</span>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>Last Name</label>
                        <input type="text" class="form-control" v-model="form.last_name" />
                        <span v-if="errors.last_name" class="text-danger">{{ errors.last_name[0] }}</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" class="form-control" v-model="form.email" disabled readonly/>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>Password</label>
                        <input type="password" class="form-control" v-model="form.password" />
                        <input v-if="form.password" type="password" class="form-control mt-1" v-model="form.password_confirmation" placeholder="Password Confirm" />
                        <span v-if="errors.password" class="text-danger">{{ errors.password[0] }}</span>
                        <span v-else class="text-muted"><small>Leave blank if you don't want to change it.</small></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <h2 class="h4">Shipping Address</h2>

             <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>First Name</label>
                        <input type="text" class="form-control" v-model="form.shipping_first_name" />
                        <span v-if="errors.shipping_first_name" class="text-danger">{{ errors.shipping_first_name[0] }}</span>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>Last Name</label>
                        <input type="text" class="form-control" v-model="form.shipping_last_name" />
                        <span v-if="errors.shipping_last_name" class="text-danger">{{ errors.shipping_last_name[0] }}</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" class="form-control" v-model="form.shipping_email"/>
                        <span v-if="errors.shipping_email" class="text-danger">{{ errors.shipping_email[0] }}</span>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>Phone</label>
                        <input type="text" class="form-control" v-model="form.shipping_phone" />
                        <span v-if="errors.shipping_phone" class="text-danger">{{ errors.shipping_phone[0] }}</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8">
                    <div class="form-group">
                        <label>Address 1</label>
                        <input type="text" class="form-control" v-model="form.shipping_address_1" />
                        <span v-if="errors.shipping_address_1" class="text-danger">{{ errors.shipping_address_1[0] }}</span>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>Address 2</label>
                        <input type="text" class="form-control" v-model="form.shipping_address_2" />
                        <span v-if="errors.shipping_address_2" class="text-danger">{{ errors.shipping_address_2[0] }}</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>City</label>
                        <input type="text" class="form-control" v-model="form.shipping_city" />
                        <span v-if="errors.shipping_city" class="text-danger">{{ errors.shipping_city[0] }}</span>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>Province</label>
                        <input type="text" class="form-control" v-model="form.shipping_province" />
                        <span v-if="errors.shipping_province" class="text-danger">{{ errors.shipping_province[0] }}</span>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>Postal</label>
                        <input type="text" class="form-control" v-model="form.shipping_postal" />
                        <span v-if="errors.shipping_postal" class="text-danger">{{ errors.shipping_postal[0] }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center">
            <input type="submit" class="btn btn-primary" value="Save" />
            <router-link :to="{ name: 'orderHistory' }" class="btn btn-outline-secondary ml-2">Cancel</router-link>
            <span v-if="saving" class="ml-3"><img src="/images/icons/spinner.svg" width="24px" height="24px" class="spin" alt="Loading..." /></span>
        </div>
    </form>
</template>

<script>
const saveUrl = process.env.VUE_APP_API_WHOLESALE + '/api/user/profile';

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            form: {
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                password_confirmation: '',
                shipping_first_name: '',
                shipping_last_name: '',
                shipping_phone: '',
                shipping_email: '',
                shipping_address_1: '',
                shipping_address_2: '',
                shipping_city: '',
                shipping_province: '',
                shipping_postal: '',
            },

            errors: [],

            saving: false,
        }
    },

    methods: {
        handleSubmit () {
            this.errors = [];
            this.saving = true;
            this.$emit('saving');

            this.axios.post(saveUrl, this.form)
                .then(response => {
                    this.form.password = '';
                    this.form.password_confirmation = '';
                    this.$store.dispatch('updateUserProfile', response.data);
                    this.$emit('saved');
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        return;
                    }

                    throw error;
                })
                .finally(() => {
                    this.saving = false;
                });
        },

        resetForm () {
            this.form.first_name = this.user.first_name;
            this.form.last_name = this.user.last_name;
            this.form.email = this.user.email;
            this.form.password = '';
            this.form.password_confirmation = '';
            this.form.shipping_first_name = this.user.shipping_first_name;
            this.form.shipping_last_name = this.user.shipping_last_name;
            this.form.shipping_phone = this.user.shipping_phone;
            this.form.shipping_email = this.user.shipping_email;
            this.form.shipping_address_1 = this.user.shipping_address_1;
            this.form.shipping_address_2 = this.user.shipping_address_2;
            this.form.shipping_city = this.user.shipping_city;
            this.form.shipping_province = this.user.shipping_province;
            this.form.shipping_postal = this.user.shipping_postal;
        },
    },

    watch: {
        user: {
            deep: true,
            handler () {
                this.resetForm();
            }
        }
    },

    mounted () {
        this.resetForm();
    }
}
</script>

<style scoped>
.form-group {
    margin-bottom: 0.75rem;
}
</style>
