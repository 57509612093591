<template>
    <div class="d-flex align-items-center card-wrapper" :class="{ checked: isChecked }">
        <input
            type="radio"
            name="card"
            :id="inputId"
            :checked="isChecked"
            :value="value"
            @change="$emit('change', $event.target.value)"
        />

        <label :for="inputId">
            <template v-if="card">
                <span v-if="card.brand">
                    <span class="text-capitalize">{{ brandName }} ({{ card.last_four }})</span> <br>
                </span>
                <span v-else><span>{{ card.last_four }}</span> <br></span>
                <span>Exp. {{ expiry }}</span>

                <a href="#" class="ml-3 card-remove" @click.prevent.stop="handleRemove">
                    <small>Remove</small>
                </a>
            </template>

            <template v-else>
                <span>Credit Card</span>
            </template>
        </label>

        <span class="brand">
            <img :src="brandImage" width="48px" height="auto" :class="brandClass" />
        </span>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'modelValue',
        event: 'change'
    },

    props: {
        modelValue: {
            type: [String],
        },
        value: {
            type: [String],
        },
        card: {
            type: Object
        }
    },

    data() {
        return {
            token: null,
        }
    },

    computed: {
        inputId () {
            return this.card ? 'card_' + this.card.id : 'card_default';
        },

        isChecked() {
            return this.modelValue == this.value
        },

        brandName () {
            switch (this.card.brand) {
                case 'mastercard':
                    return 'MasterCard';
                case 'visa':
                    return 'Visa';
                case 'amex':
                    return 'American Express';
                case 'discover':
                    return 'Discover';
                default:
                    return this.card.brand;
            }
        },

        brandImage () {
            switch (this.card?.brand) {
                case 'mastercard':
                    return '/images/mastercard.svg';
                case 'visa':
                    return '/images/visa-blue.svg';
                case 'amex':
                    return '/images/american-express-wide-sm.png';
                case 'discover':
                    return '/images/discover.jpg';
                default:
                    return '/images/credit-card.svg';
            }
        },

        brandClass () {
             switch (this.card?.brand) {
                case 'mastercard':
                case 'visa':
                    return 'bg-logo';
                default:
                    return '';
            }
        },

        // * Convert from YYMM to MM/YY format
        expiry () {
            const year = this.card.expiry.slice(0, 2);
            const month = this.card.expiry.slice(2);

            return `${month}/${year}`;
        },
    },

    methods: {
        handleRemove () {
            this.$emit('remove-card', this.card);
        },
    },

}
</script>

<style scoped>
    .bg-logo {
        background: #efefef;
    }

    .brand {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .brand img {
        max-height: 38px;
    }

    .brand a {
        line-height: 1;
        font-size: 14px;
    }

    .card-remove {
        display: none;
    }

    .card-wrapper:active .card-remove,
    .card-wrapper:focus .card-remove,
    .card-wrapper:hover .card-remove {
        display: initial;
    }

    @media (max-width: 992px) {
        .card-wrapper.checked .card-remove {
            display: initial;
        }
    }

</style>