<template>
    <div class="footer-summary">
        <div class="order-total">
            <span class="d-none d-md-inline">Order</span> Total: ${{ subtotal.toFixed(2) }}
        </div>

        <button class="btn btn-primary ml-4" :disabled="subtotal == 0" @click="handleToCheckout">
            Checkout
        </button>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters(['subtotal']),
        },

        methods: {
            handleToCheckout () {
                this.$router.push('/cart');
            }
        }
    }
</script>

<style scoped>
    .footer-summary {
        display: none;
        padding: 0.5rem 1rem;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        background: #fff;
        z-index: 15;
        border-top: 1px solid #85C71E;
        align-items: center;
        justify-content: space-between;
    }

    .order-total {
        font-size: 1.125rem;
        font-weight: 600;
    }

    .btn {
        padding: 4px 8px;
    }

    @media (min-width: 768px) {
        .footer-summary {
            padding: 1rem;
        }

        .btn {
            padding: 6px 12px;
        }
    }

    @media (min-width: 992px) {
        .footer-summary {
            justify-content: initial;
            display: flex;
        }

        .order-total {
            margin-left: auto;
        }
    }
</style>