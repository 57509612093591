<template>
    <div>
        <div class="credit-card" v-if="! useVeeValidate">
            <input
                v-model="cardnumber"
                type="text"
                name="cardnumber"
                class="form-control cardnumber"
                placeholder="Card Number"
                v-mask="cardMask"
                :class="{ 'has-error': ! isCardNumberPotentiallyValid }"
                :required="validate"
                :disabled="!isShippingMethodComplete || !isShippingAddressComplete"
                @blur="validateCardNumber"
            />
            <input
                v-model="expiry"
                type="text"
                step="1"
                name="expiry"
                placeholder="MMYY"
                maxlength="4"
                class="form-control expiry"
                v-mask="'####'"
                :class="{ 'has-error': ! isExpiryPotentiallyValid }"
                @keydown="checkElementFocus"
                @blur="validateExpiry"
                :disabled="!isShippingMethodComplete || !isShippingAddressComplete"
            />
            <input
                v-model="cvd"
                type="text"
                step="1"
                name="cvd"
                class="form-control cvd"
                :class="{ 'has-error': ! isCvdPotentiallyValid }"
                v-mask="cvdMask"
                @keydown="checkElementFocus"
                @blur="validateCvd"
                placeholder="CVC"
                :maxlength="maxlength"
                :disabled="!isShippingMethodComplete || !isShippingAddressComplete"
            />
        </div>

        <div class="credit-card" v-if="validate && useVeeValidate">
            <input
                v-model="cardnumber"
                type="text"
                name="cardnumber"
                placeholder="Card Number"
                class="form-control cardnumber"
                :class="{ 'has-error': ! isCardNumberPotentiallyValid }"
                v-mask="cardMask"
                @blur="validateCardNumber"
                data-vv-name="cardnumber"
                v-validate="'required'"
                :disabled="!isShippingMethodComplete || !isShippingAddressComplete"
            />
            <input
                v-model="expiry"
                type="text"
                step="1"
                name="expiry"
                class="form-control expiry"
                :class="{ 'has-error': ! isExpiryPotentiallyValid }"
                v-mask="'####'"
                @keydown="checkElementFocus"
                @blur="validateExpiry"
                placeholder="MMYY"
                maxlength="4"
                data-vv-name="expiry"
                v-validate="'required'"
                :disabled="!isShippingMethodComplete || !isShippingAddressComplete"
            />

            <input
                v-model="cvd"
                type="text"
                step="1"
                name="cvd"
                placeholder="CVC"
                class="form-control cvd"
                :class="{ 'has-error': ! isCvdPotentiallyValid }"
                v-mask="cvdMask"
                @keydown="checkElementFocus"
                @blur="validateCvd"
                :maxlength="maxlength"
                :disabled="!isShippingMethodComplete || !isShippingAddressComplete"
                data-vv-name="cvd"
                v-validate="'required'"
            />
        </div>
        <span class="error" v-text="errorMessage"></span>
    </div>
</template>

<script>
var validator = require('card-validator');

import { mask } from 'vue-the-mask'

export default {
    directives: {
        mask,
    },

    props: [
        'errorMessage',
        'validate',
        'useVeeValidate',
        'isShippingMethodComplete',
        'isShippingAddressComplete',
        'initialCardnumber',
        'initialExpiry',
        'initialCvd',
        'isOrderComplete'
    ],

    data () {
        return {
            brand: '',
            cardnumber: '',
            expiry: '',
            cvd: '',

            isCardNumberPotentiallyValid: true,
            isCardNumberValid: false,
            isExpiryPotentiallyValid: true,
            isExpiryValid: false,
            isCvdPotentiallyValid: true,
            isCvdValid: false,
        }
    },

    computed: {
        isCreditCardValid () {
            return this.isCardNumberValid && this.isExpiryValid && this.isCvdValid;
        },

        cardMask () {
            return this.brand === 'american-express' ? '#### ###### #####' : '#### #### #### ####';
        },

        cvdMask () {
            return this.brand === 'american-express' ? '####' : '###';
        },

        maxlength () {
            return this.brand === 'american-express' ? 4 : 3;
        }
    },

    watch: {
        cardnumber () {
            var v = validator.number(this.cardnumber);

            if (v.isValid) {
                this.$el.querySelector('.expiry').focus();
            }

            if (v.card) {
                this.brand = v.card.type;
            }

            this.isCardNumberValid = v.isValid;
            this.isCardNumberPotentiallyValid = v.isPotentiallyValid;
            this.notify();
        },

        expiry() {
            var v = validator.expirationDate(this.expiry);

            // Validator lets 3 digit expiries go through but our UI makes that confusing,
            // so we're forcing 4 digits before evaluating.
            if (v.isValid && this.expiry.length === 4) {
                this.$el.querySelector('.cvd').focus();
                this.isExpiryValid = true;
            } else {
                this.isExpiryValid = false;
            }

            this.isExpiryPotentiallyValid = v.isPotentiallyValid;
            this.notify();
        },

        cvd () {
            var v = validator.cvv(this.cvd, this.brand === 'american-express' ? 4 : 3);
            this.isCvdValid = v.isValid;
            this.isCvdPotentiallyValid = v.isPotentiallyValid;
            this.notify();
        },

        isOrderComplete (newValue) {
            if (newValue) {
                this.cardnumber = '';
                this.expiry = '';
                this.cvd = '';
                this.brand = '';
            }
        }
    },

    methods: {
        validateCardNumber() {
            this.isCardNumberValid = validator.number(this.cardnumber).isValid;
            this.isCardNumberPotentiallyValid = this.isCardNumberValid;
        },

        validateExpiry() {
            this.isExpiryValid = validator.expirationDate(this.expiry).isValid && this.expiry.length === 4;
            this.isExpiryPotentiallyValid = this.isExpiryValid;
        },

        validateCvd() {
            this.isCvdValid = validator.cvv(this.cvd, this.brand === 'american-express' ? 4 : 3).isValid;
            this.isCvdPotentiallyValid = this.isCvdValid;
        },

        checkElementFocus(e) {
            if (e.target.value === '' && e.key === 'Backspace') {
                e.target.previousElementSibling.focus();
            }
        },

        notify() {
            var data = {
                cardnumber: this.isCreditCardValid ? this.cardnumber : '',
                expiry: this.isCreditCardValid ? this.expiry : '',
                cvd: this.isCreditCardValid ? this.cvd : '',
            };

            this.$emit('credit-card-update', data);
        }
    },

    created() {
        if (this.useVeeValidate) {
            this.$validator = this.$parent.$validator;
        }
    },
}
</script>

<style scoped>
    .credit-card {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .credit-card input:last-child {
        margin-right: 0;
    }

    .credit-card input:first-child {
        flex: 3;
    }

    .credit-card input {
        flex: 1;
    }

    .credit-card .has-error {
        border-color: #cc0000;
        box-shadow: none;
    }

    .error {
        display: block;
        color: #cc0000;
        font-size: 15px;
    }
</style>
