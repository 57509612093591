<template>
    <div class="container content">
        <h1>Page Not Found</h1>

        <p>The page you're looking for could not be found.</p>

        <router-link to="/" class="btn btn-primary">View our Catalog</router-link>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
