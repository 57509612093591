<template>
    <modal v-if="show" @close="$emit('close')" size="large" class="outlined howitworks" :closeable="true">
        <template #header>
            <h1 class="text-center w-100">How It Works</h1>

            <a href="#" class="close-modal m-3" @click.prevent="$emit('close')">X</a>
        </template>

        <template #body>
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <p class="text-center">Here are 3 easy steps to order your fresh foods for delivery or pick up.</p>

                        <div class="d-flex align-items-center mb-4">
                            <div class="step">1</div>
                            <p class="mb-0">Shop for your favourite fruits, veggies, and other fresh food!</p>
                        </div>

                        <div class="d-flex align-items-center mb-4">
                            <div class="step">2</div>
                            <p class="mb-0">Select your shipping method; have it delivered straight to your doorstep, or pick it up at our warehouse in Woodstock, Ancaster, Norwich, or Thamesford.</p>
                        </div>

                        <div class="d-flex align-items-center mb-4">
                            <div class="step">3</div>
                            <p class="mb-0">Enter your payment information and submit your order! You will receive a confirmation email with your receipt and order details.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <iframe width="340" height="480" src="https://www.youtube.com/embed/pbXw9ciFVaQ?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </template>

        <template #footer></template>
    </modal>
</template>

<script>
    import Modal from '@/components/Modal'

    export default {
        components: {
            Modal,
        },

        props: ['show'],
    }
</script>

<style scoped>
    .close-modal {
        color: #FF5047;
        line-height: 1;
    }

    .close-modal:hover {
        text-decoration: none;
    }

    h1:after {
        display: block;
        content: '';
        border-bottom: 2px solid #FF5047;
        width: 180px;
        height: 1px;
        margin: 0.5rem auto;
    }

    .step {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        font-size: 1.75rem;
        content: "";
        background: #FF5047;
        color: #fff;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        flex: 0 0 auto;
        margin-right: 1rem;
    }

    .col-md-6 {
        margin-bottom: 1rem;
    }

    @media (min-width: 768px) {
        .col-md-6 {
            margin-bottom: 0;
        }

        .step {
            width: 64px;
            height: 64px;
        }
    }
</style>