<template>
    <my-account-layout>
        <h1 class="mb-3">Edit Profile</h1>

        <alert :message="message" v-if="message" :alert-style="'alert-success'" @close="message = ''" />

        <form-user-profile :user="currentUser" v-if="currentUser" @saved="handleSaved" @saving="handleSaving" />
    </my-account-layout>
</template>

<script>
import { mapState } from 'vuex';
import MyAccountLayout from '@/views/Layout/MyAccount';
import FormUserProfile from '@/components/FormUserProfile.vue';
import Alert from '@/components/Alert.vue';

export default {
    name: 'edit-profile',

    components: {
        MyAccountLayout,
        FormUserProfile,
        Alert,
    },

    data() {
        return {
            message: '',
        }
    },

    methods: {
        handleSaving () {
            this.message = '';
        },

        handleSaved () {
            this.message = 'User Profile Saved.'
            window.scrollTo(0, 0);
        },
    },

    computed: {
        ...mapState({
            currentUser: state => state.user.currentUser,
        }),
    },
}
</script>

<style scoped>
label {
    font-size: 16px;
    margin-bottom: 0.25rem;
}

.form-group {
    margin-bottom: 0.5rem;
}
</style>