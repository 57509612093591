import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Product from '../views/Product.vue'
import Cart from '../views/Cart.vue'
import Checkout from '../views/Checkout.vue'
import OrderComplete from '../views/OrderComplete.vue'
import EditProfile from '../views/EditProfile.vue'
import OrderHistory from '../views/OrderHistory/Index.vue'
import OrderDetails from '../views/OrderHistory/Details.vue'
import Maintenance from '../views/Maintenance.vue';
import PageNotFound from '../views/PageNotFound.vue'
import store from '@/store';
import cookie from 'vue-cookies'

Vue.use(VueRouter)

const loginMessage = 'Authentication required, please sign in.';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        props: route => ({ login: route.query.login, loginMessage: loginMessage }),
    },
    {
        path: '/product/:slug/quickview',
        name: 'quickview',
        component: Home,
        props: route => ({ login: route.query.login, loginMessage: loginMessage }),
    },
    {
        path: '/category/:slug',
        name: 'category',
        component: Home,
    },
    {
        path: '/delivery-check',
        name: 'delivery-check',
        component: Home,
    },
    {
        path: '/about',
        name: 'about',
        component: Home,
    },
    {
        path: '/how-it-works',
        name: 'how-it-works',
        component: Home,
    },
    {
        path: '/contact',
        name: 'contact',
        component: Home,
    },
    {
        path: '/login',
        name: 'login',
        component: Home,
    },
    {
        path: '/register',
        name: 'register',
        component: Home,
    },
    {
        path: '/referral',
        name: 'referral',
        component: Home,
    },
    {
        path: '/product/:slug',
        name: 'product',
        component: Product,
    },
    {
        path: '/cart',
        name: 'cart',
        component: Cart,
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: Checkout,
    },
    {
        path: '/404',
        name: 'pageNotFound',
        component: PageNotFound,
    },
    {
        path: '/thankyou',
        name: 'orderComplete',
        component: OrderComplete,
    },
    {
        path: '/referral',
        redirect: { path: '/', hash: '#referral'},
    },
    {
        path: '/my-account/profile',
        name: 'editProfile',
        component: EditProfile,
        meta: { requiresAuth: true },
    },
    {
        path: '/my-account/orders',
        name: 'orderHistory',
        component: OrderHistory,
        meta: { requiresAuth: true },
    },
    {
        path: '/my-account/orders/:id',
        name: 'orderDetails',
        component: OrderDetails,
        meta: { requiresAuth: true },
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance,
    },
    {
        path: '*',
        redirect: '/404'
    }
]

function setUtmCookies(to) {
    const queryParams = ['utm_campaign', 'utm_source', 'utm_medium', 'utm_content', 'utm_term'];
    
    queryParams.forEach(param => {
        const value = to.query[param];
        if (value) {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 7); // Set expiration date to 7 days from now
            
            // Set the parameter to a cookie with an expiration date
            cookie.set(param, value, expirationDate);
        }
    });
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    setUtmCookies(to);

    if (to.matched.some(record => record.meta.requiresAuth) && !store.state.user.currentUser) {
        store.dispatch('getCurrentUser').then(() => {
            if (store.state.user.currentUser === null) {
                next({ name: 'home', replace: true });
            } else {
                next();
            }
        });
    } else {
        if (store.state.user.currentUser === null) {
            store.dispatch('getCurrentUser');
        }

        next();
    }
});

router.afterEach((to, from) => {
    const allowRoutes = ['home', 'product', 'cart', 'checkout'];

    if (!from.name || (allowRoutes.includes(from.name) && allowRoutes.includes(to.name))) {
        store.dispatch('setDeliveryTimesCounter');
    }
});

export default router
