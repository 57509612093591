<template>
    <modal v-if="show" @close="$emit('close', disableModal)" size="large" class="outlined about" :closeable="true">
        <template #header>
            <a href="#" class="close-modal m-2" @click.prevent="$emit('close', disableModal)">X</a>
        </template>

        <template #body>
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <div class="pr-0 col-4">
                        <img src="/images/logo_s.png" alt="Produce Express" class="logo" width="200px" height="auto" />
                    </div>

                    <div class="pl-0 col-8">
                        <p class="main-text">Check out our new and improved delivery times!</p>
                        <small>*only applies to some locations</small>

                        <div>
                            <label for="show_again" class="d-inline-flex align-items-center show-again">
                                <input type="checkbox" id="show_again" v-model="disableModal" class="large mr-1" />
                                Do not show this again
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
        </template>
    </modal>
</template>

<script>
    import Modal from '@/components/Modal'

    export default {
        components: {
            Modal,
        },

        props: ['show'],

        data() {
            return {
                disableModal: false,
            }
        },
    }
</script>

<style scoped>
    .close-modal {
        color: #FF5047;
        line-height: 1;
    }

    .close-modal:hover {
        text-decoration: none;
    }

    small {
        color: #85C71E;
        font-size: 14px;
        margin: 0;
        padding: 0;
        font-weight: 600;
    }

    .main-text {
        color: #85C71E;
        font-size: 2.5rem;
        font-weight: 600;
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    .show-again {
        color: #FF5047;
        font-size: 14px;
        font-weight: 700;
        display: block;
        margin-bottom: 0;
    }
</style>
