<template>
    <div>
        <h4 class="mb-4">Items you love</h4>

        <ul v-if="favourites.length" class="mb-0">
            <li v-for="product in favourites" :key="product.id" class="mb-4">
                <div class="favourite">
                    <div class="image">
                        <img :src="product.image" />
                    </div>

                    <div class="details">
                        <h5 class="mb-1">{{ product.title }}</h5>
                        <p class="mb-0">{{ parseFloat(product.price) | toCurrency }} / {{ product.size }}</p>

                        <product-add-to-cart :product="product" :show-add-to-cart-button="true" btn-size="btn-sm" />
                    </div>
                </div>
            </li>
        </ul>

        <p v-else>No favourites at this time</p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductAddToCart from '@/components/ProductAddToCart';

export default {
    components: {
        ProductAddToCart,
    },

    computed: {
        ...mapGetters(['favourites']),
    },
}
</script>

<style scoped>
ul {
    list-style-type: none;
    padding-left: 0;
}

li:last-child {
    margin-bottom: 0 !important;
}

.favourite {
    display: flex;
}

.favourite .image {
    max-width: 60px;
    margin-right: 1.5rem;
}

@media (min-width: 768px) and (max-width: 992px) {
    .favourite .image {
        display: none;
    }

    .favourite .details >>> .btn-add {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}

.favourite .details {
    flex: 1;
}

.favourite .details h5 {
    font-size: 1.125rem;
}

.favourite .details p {
    font-size: 1rem;
}

h4 {
    border-bottom: 2px solid #dee2e6;
    padding-bottom: 0.25rem;
    color: #bbb;
    font-size: 1.2rem;
    font-weight: 700;
}

>>> .input-group {
    width: 100%;
}

>>> .input-group,
>>> .input-group .qty {
    height: 31px;
}

>>> .btn-plus,
>>> .btn-minus {
    font-size: 1.25rem;
}

>>> .add-to-cart {
    width: 100%;
}

</style>