<template>
    <div class="container-xl content">
         <div class="row">
            <div class="col-md-3 col-lg-3">
                <user-sidebar />

                <user-favourites class="favourites" />
            </div>

            <div class="col-md-9 col-lg-9">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import UserSidebar from '@/components/UserSidebar.vue';
import UserFavourites from '@/components/UserFavourites';

export default {
    components: {
        UserSidebar,
        UserFavourites,
    },
}
</script>

<style scoped>
    .favourites {
        border: 1px solid #dee2e6;
        padding: 0.5rem 1rem;
        border-radius: 6px;
        margin-bottom: 1.5rem;
    }
</style>