<template>
<div class="row">
    <div class="col-md-6">
        <div class="p-3 mt-3 form-payment-default">
            <div class="form-group">
                <label for="">Credit Card</label>
                <input
                    type="text"
                    v-model="cardnumber"
                    v-mask="cardMask"
                    :class="{ 'has-error': ! isCardNumberPotentiallyValid }"
                    :required="validate"
                    :disabled="!isShippingMethodComplete"
                    class="form-control cardnumber"
                    placeholder="Credit Card Number"
                    @blur="validateCardNumber"
                />
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Expiration Date</label>
                        <input
                            type="text"
                            v-model="expiry"
                            v-mask="'####'"
                            :class="{ 'has-error': ! isExpiryPotentiallyValid }"
                            :disabled="!isShippingMethodComplete"
                            class="form-control expiry"
                            placeholder="MMYY"
                            @keydown="checkElementFocus"
                            @blur="validateExpiry"
                        />
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Security Code (CVV)</label>
                        <input
                            type="text"
                            v-model="cvd"
                            v-mask="cvdMask"
                            class="form-control cvd"
                            :class="{ 'has-error': ! isCvdPotentiallyValid }"
                            :maxlength="maxlength"
                            :disabled="!isShippingMethodComplete"
                            placeholder="XXX"
                            @keydown="checkElementFocus"
                            @blur="validateCvd"
                        />
                    </div>
                </div>
            </div>

            <!-- <div class="form-group">
                <label for="">Card Holder Name</label>
                <input v-model="card_holder_name" type="text" class="form-control" />
            </div> -->

            <div>
                <label for="save_card_details">
                    <input
                        type="checkbox"
                        v-model="saveToVault"
                        value="1"
                        id="save_card_details"
                        @change="notify"
                    />
                    Save card details for next time
                </label>
            </div>
        </div>
    </div>
</div>
</template>

<script>
var validator = require('card-validator');

import { mask } from 'vue-the-mask'

export default {
    directives: {
        mask,
    },

    props: [
        'errorMessage',
        'validate',
        'useVeeValidate',
        'isShippingMethodComplete',
        'initialCardnumber',
        'initialExpiry',
        'initialCvd',
        'isOrderComplete'
    ],


    data() {
        return {
            brand: null,
            cardnumber: null,
            expiry: null,
            cvd: null,
            saveToVault: null,

            isCardNumberPotentiallyValid: true,
            isCardNumberValid: false,
            isExpiryPotentiallyValid: true,
            isExpiryValid: false,
            isCvdPotentiallyValid: true,
            isCvdValid: false,
        }
    },

    computed: {
        isCreditCardValid () {
            return this.isCardNumberValid && this.isExpiryValid && this.isCvdValid;
        },

        cardMask () {
            return this.brand === 'american-express' ? '#### ###### #####' : '#### #### #### ####';
        },

        cvdMask () {
            return this.brand === 'american-express' ? '####' : '###';
        },

        maxlength () {
            return this.brand === 'american-express' ? 4 : 3;
        }
    },

    methods: {
        validateCardNumber() {
            this.isCardNumberValid = validator.number(this.cardnumber).isValid;
            this.isCardNumberPotentiallyValid = this.isCardNumberValid;
        },

        validateExpiry() {
            this.isExpiryValid = validator.expirationDate(this.expiry).isValid && this.expiry.length === 4;
            this.isExpiryPotentiallyValid = this.isExpiryValid;
        },

        validateCvd() {
            this.isCvdValid = validator.cvv(this.cvd, this.brand === 'american-express' ? 4 : 3).isValid;
            this.isCvdPotentiallyValid = this.isCvdValid;
        },

        checkElementFocus(e) {
            if (e.target.value === '' && e.key === 'Backspace') {
                e.target.previousElementSibling.focus();
            }
        },

        notify() {
            this.$emit('credit-card-update', {
                cardnumber: this.isCreditCardValid ? this.cardnumber : '',
                expiry: this.isCreditCardValid ? this.expiry : '',
                cvd: this.isCreditCardValid ? this.cvd : '',
                save_to_vault: this.saveToVault,
            });
        }
    },

    watch: {
        cardnumber () {
            var v = validator.number(this.cardnumber);

            if (v.isValid) {
                this.$el.querySelector('.expiry').focus();
            }

            if (v.card) {
                this.brand = v.card.type;
            }

            this.isCardNumberValid = v.isValid;
            this.isCardNumberPotentiallyValid = v.isPotentiallyValid;
            this.notify();
        },

        expiry() {
            var v = validator.expirationDate(this.expiry);

            // Validator lets 3 digit expiries go through but our UI makes that confusing,
            // so we're forcing 4 digits before evaluating.
            if (v.isValid && this.expiry.length === 4) {
                this.$el.querySelector('.cvd').focus();
                this.isExpiryValid = true;
            } else {
                this.isExpiryValid = false;
            }

            this.isExpiryPotentiallyValid = v.isPotentiallyValid;
            this.notify();
        },

        cvd () {
            var v = validator.cvv(this.cvd, this.brand === 'american-express' ? 4 : 3);
            this.isCvdValid = v.isValid;
            this.isCvdPotentiallyValid = v.isPotentiallyValid;
            this.notify();
        },

        isOrderComplete (newValue) {
            if (newValue) {
                this.cardnumber = '';
                this.expiry = '';
                this.cvd = '';
                this.brand = '';
                this.saveToVault = null;
            }
        }
    },
}
</script>

<style scoped>
    .form-payment-default {
        background: #e9e9e9;
        border-radius: 0.25rem;
    }

    .has-error {
        border-color: #cc0000;
        box-shadow: none;
    }
</style>