<template>
    <a href="#" class="nav-link dropdown-toggle user-menu" aria-expanded="false" data-toggle="dropdown">
        <img src="/images/icons/user.svg" width="26px" height="26px" class="icon" alt="User" style="margin-top: -3px"/>
        <img src="/images/icons/user-solid.svg" width="26px" height="26px" class="icon-solid" alt="User" style="margin-top: -3px" />
        <span class="pl-1 users-name">Hello <span>{{ currentUser.first_name }}</span></span>

        <div class="dropdown-menu">
            <router-link class="dropdown-item" :to="{ name: 'orderHistory' }">My Account</router-link>
            <a class="dropdown-item" href="#" @click.prevent="handleLogout">Sign Out</a>
        </div>
    </a>
</template>

<script>
export default {
    props: ['currentUser'],

    methods: {
        handleLogout () {
            this.$store.dispatch('signOut').then(() => {
                window.location = '/';
            });
        },
    },

    mounted () {
        window.$('.user-menu').dropdown();
    }
}
</script>

<style scoped>
    .users-name {
        display: none;
    }

    @media (min-width: 768px) {
        .users-name {
            display: inline-block;
        }
    }
</style>