<template>
    <div class="sidebar h-100" id="sidebar">
        <div class="content">
            <div class="d-flex align-items-center justify-content-between d-lg-block">
                <div class="dropdown d-lg-none">
                    <button class="btn btn-primary btn-sm dropdown-toggle rounded-pill" type="button" id="dropdownMenuButton" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                        Filter Products
                    </button>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <label class="dropdown-item" :class="{ active: this.categoryFilter.length === 0 }">
                            <a href="#" @click.prevent="handleFilter('all')">All</a>
                        </label>

                        <label class="dropdown-item" v-for="filter in availableCategoryFilters" :key="filter.slug" :class="{ 'active': isActive(filter.slug), 'canning': isCanning(filter.slug) }">
                            <!-- Items with no subcategories -->
                            <a href="#" @click.prevent="handleFilter(filter.slug), submenuOpen = null" v-if="filter.subcategories.length == 0 && filter.slug !== 'pinned' || (filter.slug === 'pinned' && hasPins)">
                                {{ filter.name }}
                            </a>

                            <!-- Items with subcategories -->
                            <a v-if="filter.subcategories.length" class="dropdown-toggle" @click.stop="toggleSubmenu(filter.slug)" :class="{ 'active': isActive(filter.slug) || showSubcategories(filter)}">
                                {{filter.name}}
                            </a>

                            <div v-if="filter.subcategories.length" v-show="submenuOpen === filter.slug">
                                <div>
                                    <a href="#" class="ml-4" @click.prevent="handleFilter(filter.slug)">
                                        Show All {{ filter.name }}
                                    </a>
                                </div>

                                <div v-for="subfilter in filter.subcategories" :key="subfilter.slug" class="subcategory" :class="{ 'active': isActive(subfilter.slug) }">
                                    <a href="#" class="ml-4" @click.prevent="handleFilter(subfilter.slug)">
                                        {{ subfilter.name }}
                                    </a>
                                </div>
                            </div>

                        </label>
                    </div>
                </div>

                <div class="form-group search">
                    <input type="text" v-model="searchTerm" class="form-control rounded-pill" placeholder="Search products..." />
                </div>
            </div>

            <div class="filter-container">
                <div class="form-group d-none d-lg-block categories">
                    <ul class="filters">
                        <li :class="{ active: this.categoryFilter.length === 0 }">
                            <a href="#" @click.prevent="handleFilter('all')">All</a>
                        </li>

                        <li v-for="filter in availableCategoryFilters" :key="filter.slug" :class="{ active: isActive(filter.slug) || showSubcategories(filter), 'canning': isCanning(filter.slug) }">
                            <a href="#" @click.prevent="handleFilter(filter.slug)" v-if="filter.slug !== 'pinned' || (filter.slug=== 'pinned' && hasPins)">
                                {{ filter.name }}
                            </a>
                            <ul v-if="filter.subcategories.length" class="subcategory_list">
                                <li v-for="subfilter in filter.subcategories" :key="subfilter.slug" class="subcategory" :class="{ 'active': isActive(subfilter.slug) }" v-show="showSubcategories(filter)">
                                    <a href="#" @click.prevent="handleFilter(subfilter.slug)">
                                        {{ subfilter.name }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div class="form-group d-none d-lg-block brands" v-if="availableBrandFilters.length">
                    <h4>Brands</h4>

                    <ul class="filters">
                        <li v-for="filter in availableBrandFilters" :key="filter.id">
                            <label>
                                <input type="checkbox" :value="filter.id" v-model="brandFilters" /> {{ filter.name }}
                            </label>
                        </li>
                    </ul>
                </div>

            </div>

            <a href="#" class="text-danger font-weight-bold d-none d-lg-block" @click.prevent="handleFilterReset">Reset Filters</a>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        data () {
            return {
                staticCategoryFilters: [
                    { id: null, name: 'My Favourites', slug: 'pinned', subcategories: []},
                    { id: null, name: 'Specials', slug: 'special', subcategories: []},
                    { id: null, name: 'New Items', slug: 'new', subcategories: []},
                    { id: null, name: 'Organic', slug: 'organic', subcategories: []},
                    { id: null, name: 'Local Only', slug: 'locally_grown', subcategories: []},
                ],

                searchTerm: '',
                categoryFilter: '',
                subcategoryFilter: '',
                brandFilters: [],
                submenuOpen: null,
            }
        },

        mounted () {
            if (this.$route.name === 'category'
                && this.$route.params.slug
                && this.availableCategoryFilters.some(c => c.slug === this.$route.params.slug || c.subcategories.some(sc => sc.slug === this.$route.params.slug))) {
                this.categoryFilter = this.$route.params.slug;
            }
        },

        computed: {
            ...mapState({
                products: state => state.products.all,
                categories: state => state.categories,
            }),

            categoryFilters() {
                let staticCategories = this.staticCategoryFilters;
                let dynamicCategories = this.categories;

                return staticCategories.concat(dynamicCategories);
            },

            availableBrandFilters () {
                let brands = [];

                this.products.forEach(p => {
                    if (this.isVisible(p) && p.brand) {
                        let index = brands.findIndex(b => b.id === p.brand.id);

                        if (index === -1) {
                            brands.push({
                                id: p.brand.id,
                                name: p.brand.title,
                            });
                        }


                    }
                });

                brands.sort(function(a, b) {
                    if (a.name === b.name) {
                        return 0;
                    }

                    return a.name < b.name ? -1 : 1;
                });

                return brands;
            },

            availableCategoryFilters () {
                let categories = [];

                this.categoryFilters.forEach(c => {
                    const match = this.products.find(p => {
                        if (c.slug === 'special') {
                            return p.special === 1;
                        } else if (c.slug === 'new') {
                            return p.new === true;
                        } else if (c.slug === 'organic') {
                            return p.organic === true;
                        } else if (c.slug === 'pinned') {
                            return p.pinned === true;
                        }

                        const submatch = c.subcategories.find(sub => p.category_id === sub.id);

                        return (submatch || p.category_id === c.id) && p.active === 1;
                    });

                    if (match) {
                        categories.push(c);
                    }
                });

                // Locally grown
                let local_category = this.categoryFilters.find(c => {
                    return c.slug === 'locally_grown';
                });
                let local_product = this.products.find(p => { return p.locally_grown === 1});
                if(local_category !== undefined && local_product !== undefined) categories.push(local_category);

                // Custom category ordering
                let local_grocery_index = categories.findIndex(c => c.slug === 'local_grocery');
                let organic_index = categories.findIndex(c => c.slug === 'organic');
                if (local_grocery_index !== -1 && organic_index !== -1)
                {
                    let organic_category = categories.splice(organic_index, 1)[0];
                    categories.splice(local_grocery_index, 0, organic_category);
                }

                return categories;
            },

            hasPins () {
                let hasPins = false;

                this.products.forEach(p => {
                    if (p.pinned) {
                        hasPins = true;
                    }
                });

                return hasPins;
            },
        },


        methods: {
            handleFilterReset () {
                this.searchTerm = '';
                this.categoryFilter = '';
                this.brandFilters = [];
            },

            isVisible (product) {
                let categoryIds = [];

                if(this.categoryFilter != '') {
                    //Find the ID of the category, and of it's parent, if applicable
                    this.categoryFilters.forEach((parent) => {
                        if (parent.slug === this.categoryFilter) {
                            categoryIds.push(parent.id);

                            parent.subcategories.forEach(sub => {
                                categoryIds.push(sub.id);
                            });

                        } else if (parent.subcategories.length) {
                            parent.subcategories.forEach((s) => {
                                if(s.slug === this.categoryFilter) {
                                    categoryIds.push(s.id);
                                }
                            })
                        }
                    });
                }

                return product.active &&
                    (this.categoryFilter === '') ||
                    (categoryIds.includes(product.category_id)) ||
                    (this.categoryFilter === 'special' && product.special) ||
                    (this.categoryFilter === 'locally_grown' && product.locally_grown) ||
                    (this.categoryFilter === 'pinned' && product.pinned);
            },

            unsetBrandFilters () {
                this.brandFilters.forEach((brand, index) => {
                    if (this.availableBrandFilters.findIndex(b => b.id === brand) === -1) {
                        this.brandFilters.splice(index, 1);
                    }
                });
            },

            handleFilter(filter) {
                this.searchTerm = '';
                this.categoryFilter = filter === 'all' ? '' : filter;
                this.scrollToProductsTop(0);
            },

            isActive (filter) {
                return this.categoryFilter === filter;
            },

            isCanning (filter) {
                return filter === 'canning_items';
            },

            showSubcategories(filter) {
                return filter.slug === this.categoryFilter || filter.subcategories.some(i => i.slug === this.categoryFilter);
            },

            toggleSubmenu(filter) {
                if(this.submenuOpen === filter) this.submenuOpen = null;
                else this.submenuOpen = filter;
            },

            scrollToProductsTop(offset) {
                const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
                const headerHeight = document.getElementById('header').clientHeight;
                const sidebarHeight = document.getElementById('sidebar').clientHeight;
                const yOffset = vw >= 992 ? headerHeight : headerHeight + sidebarHeight;
                const productsContainer = window.document.getElementById('products');
                const y = productsContainer.getBoundingClientRect().top + window.pageYOffset - yOffset + offset;
                window.scrollTo(0, y);
            }
        },

        watch: {
            subcategoryFilter (newValue) {
                this.$store.commit('setSubcategoryFilter', newValue)
            },

            searchTerm (newValue, oldValue) {
                if (oldValue.length == 0 && newValue.length > 0) {
                    this.categoryFilter = '';
                    this.brandFilters = [];
                    this.scrollToProductsTop(-23);
                }
                this.$store.commit('setSearchTerm', newValue);
            },

            categoryFilter (newValue) {

                switch (newValue) {
                    case '':
                        this.$store.commit('setFoodFilters', []);
                        this.$store.commit('setPromoFilters', []);
                        break;

                    case 'special':
                    case 'new':
                    case 'organic':
                    case 'locally_grown':
                    case 'pinned':
                        this.unsetBrandFilters();
                        this.$store.commit('setPromoFilters', [newValue]);
                        this.$store.commit('setFoodFilters', []);
                        break;

                    default:
                        this.unsetBrandFilters();
                        this.$store.commit('setFoodFilters', [newValue]);
                        this.$store.commit('setPromoFilters', []);
                        break;
                }

                if (newValue) this.$router.push({ name: 'category', params: { slug: newValue }});
            },

            brandFilters (newValue) {
                this.$store.commit('setBrandFilters', [...newValue]);
            }
        }
    }
</script>

<style scoped>
    li label {
        margin-bottom: 0;
    }

    .subcategory {
        line-height: 1.4rem;
        padding-bottom: 0.3rem;
    }

    .active .subcategory + li:last-child {
        padding-bottom: 0.5rem;
    }

    .sidebar a {
        color: #444;
    }

    .sidebar .active a {
        color: #FF5047;
        font-weight: 600;
    }

    .sidebar .canning a {
        color: #85C71E;
        font-size: larger;
    }

    .sidebar .subcategory_list {
        padding-left: 20px;
        font-size:0.9rem;
        list-style-type: disc;
    }

    .sidebar .subcategory_list li {
        padding-bottom:0px;
    }

    .sidebar .subcategory a {
        color: #444;
        font-weight: normal;
    }

    .sidebar .subcategory.active a {
        color: #FF5047;
        font-weight: 600;
    }

    .sidebar .dropdown-toggle.active {
        color: #FF5047;
        font-weight: 600;
    }

    .sidebar .content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .form-group {
        margin-bottom: 0;
    }

    @media (min-width: 992px) {
        .form-group {
            margin-bottom: 1.5rem;
        }

        .sidebar .content {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    .filters {
        list-style-type: none;
        padding-left: 0;
        margin: 0;
    }

    .filters li.rounded-pill {
        background: rgba(0, 0, 0, 0.25);
        color: #fff;
        padding: 0.3rem 0.5rem;
        font-size: 0.875rem;
    }

    .search {
        width: 100%;
        margin-left: 1.5rem;
    }

    .search input {
        background: #efefef;
        border: 1px solid #85C71E;
        padding: 4px 8px;
        height: 31px;
        font-size: 0.875rem;
    }

    .search input:focus {
        box-shadow: none;
    }

    .dropdown-item {
        padding: 0;
    }

    .dropdown-item:focus,
    .dropdown-item:hover,
    .dropdown-item:active,
    .dropdown-item.active {
        background: none;
    }

    .dropdown-item a {
        display: inline-block;
        width: 100%;
        padding: 4px 24px;
    }

    @media (min-width: 768px) {
        .search input {
            font-size: 1rem;
            padding: 6px 12px;
        }
    }

    @media (min-height: 1100px) {
        .brands {
            max-height: 45.5%;
        }

        .categories {
            max-height: 45.5%;
        }
    }

    @media (min-width: 992px) {
        .search {
            margin-left: 0;
        }

        .stickied .filter-container {
            overflow-y: auto;
            height: calc(100vh - var(--header-height-stickied));
            margin-bottom: 1.5rem;
        }
    }
</style>
