function mapProductsToItems(products) {
    return products.map(product => {
        return {
            product_id: product.id,
            product_name: product.title,
            currency: "CAD",
            product_brand: product?.brand?.title ?? null,
            product_category: product?.category ?? null,
            price: product.price,
            quantity: product.quantity,
        }
    });
}

export function trackAddToCart(product, qty) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            items: [
                {
                    product_id: product.id,
                    product_name: product.title,
                    currency: "CAD",
                    product_brand: product?.brand?.title ?? null,
                    product_category: product?.category ?? null,
                    price: product.price,
                    quantity: qty,
                }
            ]
        }
    });
}

export function trackRemoveFromCart(product, qty) {
    if (qty < 0) {
        qty *= -1;
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
            items: [
                {
                    product_id: product.id,
                    product_name: product.title,
                    currency: "CAD",
                    product_brand: product?.brand?.title ?? null,
                    product_category: product?.category ?? null,
                    price: product.price,
                    quantity: qty,
                }
            ]
        }
    });
}

export function trackViewCart(products, subtotal) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "view_cart",
        ecommerce: {
            currency: 'CAD',
            value: parseFloat(subtotal),
            items: mapProductsToItems(products),
        }
    });
}

export function trackBeginCheckout(products, subtotal) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
            currency: 'CAD',
            value: parseFloat(subtotal),
            items: mapProductsToItems(products),
        }
    });
}

export function trackAddShippingInfo(products, total, shippingMethod) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "add_shipping_info",
        ecommerce: {
            currency: 'CAD',
            value: parseFloat(total),
            shipping_tier: shippingMethod,
            items: mapProductsToItems(products),
        }
    });
}


export function trackAddPaymentInfo(products, total) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
            currency: 'CAD',
            value: parseFloat(total),
            payment_type: 'Credit Card',
            items: mapProductsToItems(products),
        }
    });
}

export function trackPurchase(products, total, tax, shipping, transactionId) {
    if (!transactionId) {
        console.log('no transaction id');
        return;
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "purchase",
        ecommerce: {
            transaction_id: transactionId,
            currency: 'CAD',
            value: parseFloat(total),
            tax: parseFloat(tax),
            shipping: parseFloat(shipping),
            items: mapProductsToItems(products),
        }
    });
}

// export function trackViewItemList(products, category = 'Catalogue') {
//     if (category === '') {
//         category = 'Catalogue';
//     }

//     const items = mapProductsToItems(products);
//     const chunkSize = 200;

//     // * Items array has a limit of 200 items. GA will truncate anything after that.
//     for (let i = 0; i < items.length; i += chunkSize) {
//         const itemsChunk = items.slice(i, i + chunkSize);

//         window.dataLayer.push({ ecommerce: null });
//         window.dataLayer.push({
//             event: "view_item_list",
//             ecommerce: {
//                 item_list_name: category,
//                 items: itemsChunk,
//             }
//         });
//     }
// }

export function trackViewItem(product) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "view_item",
        ecommerce: {
            items: [
                {
                    product_id: product.id,
                    product_name: product.title,
                    currency: "CAD",
                    product_brand: product?.brand?.title ?? null,
                    product_category: product?.category ?? null,
                    price: product.price,
                    quantity: product.quantity,
                }
            ],
        }
    });
}

export function trackUserId(userId = null) {
    if (userId !== null) {
        userId = userId.toString();
    }

    window.dataLayer.push({ user_id: userId });
}
