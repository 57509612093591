<template>
    <my-account-layout>
        <h1 class="mb-3">Order History</h1>

        <loading v-if="loading" height="25vh" />

        <div v-else>
            <div class="table-responsive mb-4">
                <table class="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th>Order #</th>
                            <th>Date Ordered</th>
                            <th>Shipping Date</th>
                            <th>Shipping Method</th>
                            <th class="text-center">Items</th>
                            <th class="text-right">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders" :key="order.id">
                            <td class="whitespace-nowrap">
                                <router-link :to="{ name: 'orderDetails', params: { id: order.id }}">{{ order.order_number }}</router-link>
                            </td>
                            <td class="whitespace-nowrap">
                                <router-link :to="{ name: 'orderDetails', params: { id: order.id }}">{{ formatOrderedDate(order.created_at) }}</router-link>
                            </td>
                            <td class="whitespace-nowrap">
                                <router-link :to="{ name: 'orderDetails', params: { id: order.id }}">{{ formatShippingDate(order.ship_date) }}</router-link>
                            </td>
                            <td class="text-capitalize">
                                <router-link :to="{ name: 'orderDetails', params: { id: order.id }}">{{ order.shipping_method }}</router-link>
                            </td>
                            <td class="text-center">
                                <router-link :to="{ name: 'orderDetails', params: { id: order.id }}">{{ order.item_count * 1 }}</router-link>
                            </td>
                            <td class="text-right">
                                <router-link :to="{ name: 'orderDetails', params: { id: order.id }}">{{ order.total | toCurrency }}</router-link>
                            </td>
                        </tr>

                        <tr v-if="orders.length === 0">
                            <td colspan="6" class="text-center">No orders at this time.</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <pagination :links="links" @page-update="handlePageUpdate" />
        </div>
    </my-account-layout>
</template>

<script>
import { mapState } from 'vuex';
import { DateTime } from "luxon";
import MyAccountLayout from '@/views/Layout/MyAccount';
import Pagination from '@/components/Pagination.vue';
import Loading from '@/components/Loading';

export default {
    components: {
        MyAccountLayout,
        Pagination,
        Loading,
    },

    data() {
        return {
            orders: [],
            links: [],
            loading: false,
        }
    },

    computed: {
        ...mapState({
            currentUser: state => state.user.currentUser,
        }),
    },

    methods: {
        fetchOrders (url) {
            const route = url || process.env.VUE_APP_API_WHOLESALE + '/api/user/orders';

            this.loading = true;

            this.axios.get(route)
                .then(response => {
                    this.orders = response.data.data;
                    this.links = response.data.links;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        formatOrderedDate(date) {
            const newDate = DateTime.fromISO(date);

            return newDate.toLocaleString(DateTime.DATE_MED);
        },

        formatShippingDate(date) {
            const newDate = DateTime.fromSQL(date);

            return newDate.toLocaleString(DateTime.DATE_MED);
        },

        handlePageUpdate (url) {
            this.fetchOrders(url);
        },
    },

    created () {
        this.fetchOrders();
    },
}
</script>

<style scoped>
    .table-sm td {
        padding: 0;
    }

    .table td a {
        display: block;
        color: #444;
        text-decoration: none;
        padding: 0.3rem;
    }

    .table td a:hover {
        text-decoration: underline;
    }

    .table th {
        font-size: 1rem;
        line-height: 1.275;
        vertical-align: middle;
    }
</style>
